export const LAYOUTS = {
  CONVERTERS: 'converter',
  HEATING: 'heating',
  WATER_SEWAGE: 'water-sewage',
  VENT: 'ventilation',
}

const CONVERTER_PATH = '/konwerter-jednostek'
const HEATING_PATH = '/ogrzewanie'
const WATER_SEWAGE_PATH = '/wod-kan'
const VENT_PATH = '/wentylacja'

export const CONVERTER_ROUTES = [
  { path: `${CONVERTER_PATH}/cisnienie/`, name: 'Ciśnienie' },
  { path: `${CONVERTER_PATH}/objetosc/`, name: 'Objętość' },
  { path: `${CONVERTER_PATH}/dlugosc/`, name: 'Długość' },
  { path: `${CONVERTER_PATH}/energia/`, name: 'Energia' },
  { path: `${CONVERTER_PATH}/pole-powierzchni/`, name: 'Powierzchnia' },
  { path: `${CONVERTER_PATH}/predkosc/`, name: 'Prędkość' },
]

export const HEATING_ROUTES = [
  { path: `${HEATING_PATH}/srednice-przewodow/`, name: 'Przewody' },
  { path: `${HEATING_PATH}/autorytet-zaworu`, name: 'Autorytet zaworu' },
  { path: `${HEATING_PATH}/dobor-naczynia-przeponowego/`, name: 'Naczynie przeponowe' },
]

export const WATER_SEWAGE_ROUTES = [
  { path: `${WATER_SEWAGE_PATH}/srednice-przewodow-wodociagowych`, name: 'Przewody Wod.' },
  { path: `${WATER_SEWAGE_PATH}/kalkulator-kanalizacji/`, name: 'Kanalizacja' },
  { path: `${WATER_SEWAGE_PATH}/kalkulator-przylacza-i-sieci-wodociagowej/`, name: 'Sieć i przyłącze wod.' },
]

export const VENT_ROUTES = [
  { path: `${VENT_PATH}/dobor-kanalow-wentylacyjnych/`, name: 'Kanały wentylacyjne' },
  { path: `${VENT_PATH}/kalkulator-odzysku-ciepla/`, name: 'Odzysk ciepła' },
  {
    path: `${VENT_PATH}/kalkulator-strumienia-powietrza-i-temperatury/`,
    name: 'Strumień powietrza - temperatury',
  },
  {
    path: `${VENT_PATH}/dobor-mocy-chlodnicy-entalpia-punkt-rosy/`,
    name: 'Dobór mocy chłodnicy - Entalpia - Punkt Rosy',
  },
  {
    path: `${VENT_PATH}/kalkulator-ciezaru-kanalow-wentylacyjnych/`,
    name: 'Ciężar kanałów wentylacyjnych',
  },
]
